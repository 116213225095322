/* ProductCategory List, Vue Component */
<template>
  <v-card id="productcategory-list">
    <v-card-title>Product Categories</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <Can I="create" on="Reference">
          <v-btn color="primary" class="me-3" :to="{ name: 'productcategory-create' }">
          <v-icon size="18" class="me-1">{{ icons.mdiPlus }}</v-icon>
          <span>Create Product Category</span>
        </v-btn>
        </Can>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">

        <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mr-4"
        ></v-text-field>


      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

    <template #[`item.Name`]="{item}">
          <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'productcategory-view', params: { id: item.Id } }">
      {{ item.Name }}
    </router-link>

    </template>


      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <ActionsDropdown :item="item" :actionOptions="actionOptions" />
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import ActionsDropdown from '@/views/helpers/ActionsDropdown'
import { Can } from '@casl/vue'


export default {
  components: {
    ActionsDropdown,
    Can
  },
  setup() {

  // define cols
  const tableColumns = [
    { text: 'Name', value: 'Name' },
    {
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  // threee params for fetching
  const search = ref({});
  const filter = ref({});
  const options = ref({
    sortBy: ['Name'],
    sortDesc: [false],
    itemsPerPage: 20
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.ProductCategories || []);
  const totalItems = computed(() => store.state.app.ProductCategoriesTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // data for filter fields
  const filterItems = ref({

  });

  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchProductCategories', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });



    const actionOptions = [
      { title: 'View', icon: mdiEyeOutline, target: 'productcategory-view' },
      { title: 'Edit', icon: mdiPencilOutline, target: 'productcategory-edit' }
    ]

    return {
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      actionOptions,
      footerProps,

      Validators,
      Display,
      icons: {
        mdiPlus,
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
